import React from 'react';
import { addDays } from 'date-fns';
import { graphql, navigate } from 'gatsby';
import BodyClassName from 'react-body-classname';

import About from '../../components/Tour/TourLanding/About';
import iconInstagramAlt from '../../images/icon-instagram-alt.svg';
import ContactForm from '../../components/ContactForm/contact-form';
import TourGroups from '../../components/Tour/TourLanding/TourGroups';
import ImageGallery from '../../components/Tour/TourLanding/ImageGallery';
import BespokeTourCTA from '../../components/Tour/TourLanding/BespokeTourCTA';
import DestinationMap from '../../components/Tour/TourLanding/DestinationsMap';
import JumbotronSlider from '../../components/Tour/TourLanding/JumbotronSlider';
import DefaultLayoutComponent from '../../modules/layouts/DefaultLayoutComponent';

const Tours = ({ data }) => {
  const {
    tourLanding: { nodes: tourLandingNodes },
    tourData: { nodes: tourDataNodes },
  } = data;

  const [tourLandingData] = tourLandingNodes;
  const tourData = tourDataNodes.map((node) => {
    const itinerarySlices = node.data.body.filter((item) => item.slice_type === 'itinerary');
    const tourStartDate = new Date(node.data.startDate);
    return {
      ...node.data,
      uid: node.uid,
      startDate: tourStartDate,
      endDate: addDays(tourStartDate, itinerarySlices.length - 1),
    };
  });

  // redirect to 404 if no tour data
  if (!tourLandingData || tourData.length === 0) {
    navigate('/404');
    return null;
  }

  const tourGroupSlices = tourLandingData.data?.body?.filter((item) => item.sliceType === 'tour_groups');
  const tourGroups = tourGroupSlices?.map((item) => {
    const tours = item.items.map((tour) => {
      return tourData.find((tourItem) => tourItem.uid === tour.tour.uid);
    });
    return {
      ...item.primary,
      tours,
    };
  });

  const galleryImages = tourLandingData.data?.imageGallery?.map((item) => item.image);
  const destinations = tourData.map((tour) => {
    return {
      name: tour.title.text,
      coordinates: tour.locationPins[0]?.coordinates,
      description: tour.subtitle.text,
      image: tour.thumbnailImage.fluid.src,
    };
  });

  return (
    <BodyClassName className="body-dark tour-landing">
      <DefaultLayoutComponent
        title="Tours"
        description="Lineage Journey can offer a unique and personalized experience for those interested in exploring their family history. The tours can provide an opportunity to visit the places where one's ancestors lived, worked, and perhaps even left a mark on history. One potential tour option could be a guided tour of the ancestral hometown or village. This tour could include visits to significant landmarks, such as the family home, local churches or cemeteries, and even historical sites or museums that relate to the family's history."
      >
        <div className="site-main">
          <JumbotronSlider tourData={tourData} />

          <div className="bg-white">
            <About text={tourLandingData.data?.about?.raw} />
            <TourGroups tourGroupsData={tourGroups} />
            <BespokeTourCTA />
            <ImageGallery images={galleryImages} />
            <DestinationMap destinations={destinations} />
          </div>

          <section className="contact-section bg-dark txt-light">
            <div className="wrapper pd-x-sm ">
              <h3 className="sectionHeading pd-x-sm">drop us a line</h3>
              <div className="wrapper wrapper-lg pd-x-sm">
                <ContactForm />
              </div>
            </div>
          </section>

          <section className="index-social">
            <h3 className="sectionHeading pd-x-md">
              <a href="https://www.instagram.com/lineagejourney/" target="_blank">
                <span>Follow Us On</span>
                <span>
                  <img src={iconInstagramAlt} alt="Instagram" className="icon-insta" />
                  Instagram
                </span>
              </a>
            </h3>
            <div className="insta-gallery">
              <div className="elfsight-app-58f89894-78f0-48a8-bb54-4529326bc8b2"></div>
            </div>
          </section>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export default Tours;

export const query = graphql`
  query TourLangingPage {
    tourLanding: allPrismicTourLanding {
      nodes {
        data {
          imageGallery: image_gallery {
            image {
              alt
              url
              fluid(maxWidth: 800) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
          about {
            raw
          }
          body {
            ... on PrismicTourLandingBodyTourGroups {
              id
              sliceType: slice_type
              primary {
                backgroundImage: background_image {
                  alt
                  url
                  thumbnails
                }
                year {
                  text
                }
                description {
                  text
                }
              }
              items {
                tour {
                  id
                  uid
                  url
                }
              }
            }
          }
        }
      }
    }
    bgWatch: file(relativePath: { eq: "bg-watch.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    tourData: allPrismicTour {
      nodes {
        uid
        data {
          pricePerPersonSingleOccupancy: price_per_person_single_occupancy
          pricePerPersonDoubleOccupancy: price_per_person_double_occupancy
          thumbnailImage: thumbnail_image {
            url
            alt
            fluid(maxWidth: 1920) {
              ...GatsbyPrismicImageFluid
            }
          }
          startDate: tour_start_date
          body {
            ... on PrismicTourBodyItinerary {
              id
              slice_label
              slice_type
            }
          }
          title: tour_title {
            text
          }
          subtitle: tour_subtitle {
            text
          }
          description {
            text
          }
          locationPins: location_pins {
            coordinates {
              latitude
              longitude
            }
            markerDescription: marker_description
            markerImage: marker_image {
              alt
              url
              fluid(maxWidth: 400) {
                ...GatsbyPrismicImageFluid
              }
            }
            name
            isPostExtension: post_extension
            isPreExtension: pre_extension
          }
        }
      }
    }
  }
`;
