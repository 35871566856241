import React from 'react';

import { isBrowser } from '../../../../utils/isBrowser';
import ArrowLeftIcon from '../../../../images/icon-arrow-left-short.svg';
import ArrowRightIcon from '../../../../images/icon-arrow-right-short.svg';

// Since OwlCarousel is not SSR compatible, we need to check if we are in the browser
const OwlCarousel = (() => {
  if (isBrowser()) {
    return require('react-owl-carousel');
  } else {
    return () => null;
  }
})();

const ImageGallery = ({ images }) => {
  return (
    <section
      style={{
        backgroundColor: '#f4f5f7',
      }}
    >
      <div className="image-gallery wrapper wrapper-xl pd-x-md">
        <h2>Experience the land of Rich Culture</h2>
        <OwlCarousel
          className="carousel-stage owl-carousel la-rounded-nav"
          responsiveClass
          navigation="true"
          items={3}
          center
          loop
          navText={[
            `<img src=${ArrowLeftIcon} alt="Arrow left icon" />`,
            `<img src=${ArrowRightIcon} alt="Arrow right icon" />`,
          ]}
          responsive={{
            0: {
              items: 2.2,
              nav: true,
              dots: false,
            },
            768: {
              items: 3,
              nav: true,
              dots: true,
            },

            1200: {
              items: 3,
              nav: true,
              dots: true,
            },
          }}
        >
          {images.map((image, index) => (
            <div style={{ position: 'relative' }} key={index}>
              <div className="overlay" />
              <img
                src={image.fluid.src}
                alt={image.alt}
                style={{
                  height: '100%',
                  width: '100%',
                  objectFit: 'cover',
                }}
              />
            </div>
          ))}
        </OwlCarousel>
      </div>
    </section>
  );
};

export default ImageGallery;
