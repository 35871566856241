import React, { useRef } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';

import TwitterIcon from '../../images/icon-twitter-alt.svg';
import YoutubeIcon from '../../images/icon-youtube-alt.svg';
import FacebookIcon from '../../images/icon-facebook-alt.svg';
import InstagramIcon from '../../images/icon-instagram-alt.svg';

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  email: yup.string().required('Email is required'),
  message: yup.string().required('Message cannot be empty'),
});

const ContactForm = (props) => {
  const submitForm = async (form, { resetForm }) => {
    const slackText = {
      text: `*Name:* ${form.name}\n*Email:* ${form.email}\n*Message:* ${form.message}`,
    };

    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(slackText),
    };

    fetch(`https://hooks.slack.com/services/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`, requestOptions)
      .then((resp) => resp.json())
      .then((body) => {
        if (body) console.log(body);
      })
      .catch((err) => {
        console.log(err.message);
      });

    resetForm();
  };

  const submitRef = useRef();
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <Formik
            validateOnMount
            validationSchema={validationSchema}
            innerRef={submitRef}
            initialValues={{
              name: '',
              email: '',
              message: '',
            }}
            onSubmit={submitForm}
          >
            {({ values, errors, touched, handleChange, handleSubmit, setFieldValue }) => (
              <form onSubmit={handleSubmit} className="form-details billing-details">
                <div className="form-element">
                  <label style={{ color: 'white' }}>
                    Name<span>*</span>
                  </label>
                  <input type="text" value={values['name']} onChange={handleChange} name="name" />
                  <span style={touched['name'] && errors['name'] ? { color: '#D01', fontSize: 12,marginTop:6 } : {}}>
                    {touched['name'] && errors['name']}
                  </span>
                </div>
                <div className="form-element">
                  <label style={{ color: 'white' }}>
                    Email<span>*</span>
                  </label>
                  <input type="text" value={values['email']} onChange={handleChange} name="email" />
                  <span style={touched['email'] && errors['email'] ? { color: '#D01', fontSize: 12,marginTop:6 } : {}}>
                    {touched['email'] && errors['email']}
                  </span>
                </div>
                <div className="form-element first-name">
                  <label style={{ color: 'white' }}>
                    Message<span>*</span>
                  </label>
                  <textarea type="text" value={values['message']} onChange={handleChange} name="message" />
                  <span style={touched['message'] && errors['message'] ? { color: '#D01', fontSize: 12, marginTop:6 } : {}}>
                    {touched['message'] && errors['message']}
                  </span>
                </div>
                <button type="submit" className="btn btn-sm btn-primary-ii btn-contact">
                  Continue
                </button>
              </form>
            )}
          </Formik>
        </div>
        {/* <div className="col-md-7">
                <WorldMap />
            </div> */}
      </div>
      <div className="row">
        <div className="col-md-7">
          <div className="social-wrapper">
            <a href="https://www.facebook.com/lineagejourney/" target="__blank">
              <img src={FacebookIcon} />
            </a>
            <a href="https://www.instagram.com/lineagejourney/" target="__blank">
              <img src={InstagramIcon} />
            </a>
            <a href="https://twitter.com/lineagejourney" target="__blank">
              <img src={TwitterIcon} />
            </a>
            <a href="https://www.youtube.com/channel/UChwz5_GZN655houtFoVKQ7w" target="__blank">
              <img src={YoutubeIcon} />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
export default ContactForm;
