import React from 'react';
import ReactMapBoxGl, { Marker, ZoomControl } from 'react-mapbox-gl';

import PopUpCard from '../../TourDetails/Itinerary/Map/PopupCard';
import { formatCoordinate } from '../../../../utils/formatCoordinates';

import MarkerIcon from '../../../../images/mapbox-marker-icon-red.svg';

const initialZoomLevel = 1.5;
const initialCenter = [0.1278, 51.5074];

const Mapbox = ReactMapBoxGl({
  container: 'map',
  accessToken: process.env.GATSBY_MAPBOX_API_KEY,
  scrollZoom: false,
});

const DestinationMap = ({ destinations }) => {
  const [popup, setPopup] = React.useState(null);
  const [zoom, setZoom] = React.useState(initialZoomLevel);
  const [center, setCenter] = React.useState(initialCenter);

  const onMapUpdate = (map) => {
    const newZoom = map.getZoom();
    const newCenter = map.getCenter();

    if (map.getZoom() !== zoom) {
      setZoom(newZoom);
    }
    if (newCenter[0] === center[0] && newCenter[1] === center[1]) {
      setCenter(newCenter);
    }
  };

  return (
    <section className="destinations-map wrapper wrapper-xl pd-x-md">
      <h2>Our Destinations</h2>

      <div className="map-wrapper">
        <link href="https://api.mapbox.com/mapbox-gl-js/v1.10.1/mapbox-gl.css" rel="stylesheet" />
        <Mapbox
          style="mapbox://styles/lineagejourney/ckhz34cwa0xux1alun7bzqmq7"
          containerStyle={{
            //   height: 'calc(100vh - 255px)',
            height: '100%',
            width: '100%',
          }}
          movingMethod="easeTo"
          onZoom={(map) => {
            setZoom(map.getZoom());
          }}
          zoom={[zoom]}
          onZoomEnd={onMapUpdate}
          onMoveEnd={onMapUpdate}
          onPitchEnd={onMapUpdate}
          center={center}
          onClick={() => setPopup(null)}
        >
          <ZoomControl position="top-right" />
          {destinations.map((location, index) => (
            <Marker
              key={index}
              coordinates={formatCoordinate(location.coordinates)}
              anchor="center"
              onMouseEnter={(e) => {
                setPopup(location);
              }}
              onClick={(e) => {
                setPopup(location);
              }}
              onMouseLeave={(e) => {
                setPopup(null);
              }}
            >
              <img src={MarkerIcon} alt="Marker Icon" />
            </Marker>
          ))}

          <PopUpCard {...popup} />
        </Mapbox>
      </div>
    </section>
  );
};

export default DestinationMap;
