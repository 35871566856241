import React from 'react';
import { Box } from '@mui/material';

import bgImage from '../../../images/bg-bespokeCTA.png';
import { Link } from 'gatsby';

const BespokeTourCTA = () => {
  return (
    <Box
      className="bespoke-cta"
      sx={{
        backgroundImage: `url(${bgImage})`,
      }}
    >
      <Box className="book-now-gradiant-bg"></Box>
      <Box className="bespoke-cta-content">
        <h2>Want an exclusive bespoke tour?</h2>

        <Link to="/contact" className="btn btn-md btn-primary-ii btn-tour-jumbotron">
          Contact Us
        </Link>
      </Box>
    </Box>
  );
};

export default BespokeTourCTA;
