import React from 'react';

import Grid from '../../../Grid';
import TourSlide from './TourSlide';
import { isBrowser } from '../../../../utils/isBrowser';

// Since OwlCarousel is not SSR compatible, we need to check if we are in the browser
const OwlCarousel = (() => {
  if (isBrowser()) {
    return require('react-owl-carousel');
  } else {
    return () => null;
  }
})();

const TourGroups = ({ tourGroupsData }) => {
  return (
    <>
      {tourGroupsData.map((tourGroup, index) => (
        <section className="tour-group wrapper wrapper-xl pd-x-md" key={index}>
          <Grid
            style={{ height: '100%' }}
            sx={{
              gap: '0',
            }}
          >
            <Grid.Col xs="1 / span 2" md="1 / span 4">
              <div
                className="tour-group-heading"
                style={{
                  backgroundImage: `url(${tourGroup.backgroundImage.url})`,
                }}
              >
                <h2>{tourGroup.year.text}</h2>
                <h3>{tourGroup.description.text}</h3>
              </div>
            </Grid.Col>
            <Grid.Col xs="1 / span 2" md="5 / span 7">
              <OwlCarousel items={1} nav={true}>
                {tourGroup.tours.map((tour, index) => (
                  <TourSlide tour={tour} key={index} />
                ))}
              </OwlCarousel>
            </Grid.Col>
          </Grid>
        </section>
      ))}
    </>
  );
};

export default TourGroups;
