import { Link } from 'gatsby';
import React, { useContext } from 'react';
import { AppContext } from '../../../../apollo/wrap-root-element';
import { currencySymbolMap } from '../../../Header/currencyswitcher/CurrencySwitcher';

const TourSlide = ({ tour }) => {
  const { currency, currenciesRate } = useContext(AppContext);

  const [selectedRate] = !!currenciesRate?.length && currenciesRate.filter((elm) => elm.currency === currency);

  return (
    <div className="tour-group-content">
      <h3>{tour.title.text}</h3>
      <h4 className="subtitle">{tour.subtitle.text}</h4>
      <p className="description h-clamp-line-3">{tour.description.text}</p>

      <div className="price-wrapper">
        <h4>Starts from</h4>
        <div className="price-wrapper-inner">
          <span
            style={{
              width: '155px',
            }}
          >
            Single occupancy:
          </span>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span className="price">
              {currencySymbolMap[currency] || '$'}
              {parseFloat((selectedRate.rate * tour.pricePerPersonSingleOccupancy).toFixed(2))}
            </span>
            <span
              style={{
                lineHeight: '135.04%',
              }}
            >
              /per person
            </span>
          </div>
        </div>

        <div
          className="price-wrapper-inner"
          style={{
            marginTop: '12px',
          }}
        >
          <span
            style={{
              width: '155px',
            }}
          >
            Double occupancy:
          </span>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span className="price">
              {currencySymbolMap[currency] || '$'}
              {parseFloat((selectedRate.rate * tour.pricePerPersonDoubleOccupancy).toFixed(2))}
            </span>
            <span
              style={{
                lineHeight: '135.04%',
              }}
            >
              /per person
            </span>
          </div>
        </div>
      </div>

      <div
        className="btn-wrapper"
        style={{
          paddingTop: '24px',
        }}
      >
        <Link to={`/tours/${tour?.uid}`} className="btn btn-md btn-primary-ii w-full">
          Explore
        </Link>
      </div>
    </div>
  );
};

export default TourSlide;
