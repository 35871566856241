import React from 'react';

import Jumbotron from './Jumbotron';
import { isBrowser } from '../../../../utils/isBrowser';

// Since OwlCarousel is not SSR compatible, we need to check if we are in the browser
const OwlCarousel = (() => {
  if (isBrowser()) {
    return require('react-owl-carousel');
  } else {
    return () => null;
  }
})();

const JumbotronSlider = ({ tourData }) => {
  return (
    <section className="jumbotron-slider">
      <OwlCarousel items={1} nav={true}>
        {tourData.map((tour) => (
          <Jumbotron
            key={tour?.uid}
            backgroundImage={tour?.thumbnailImage?.fluid?.src}
            title={tour?.title?.text}
            subtitle={tour?.subtitle?.text}
            tourSlug={tour?.uid}
            tourStartDate={tour?.startDate}
            tourEndDate={tour?.endDate}
          />
        ))}
      </OwlCarousel>
    </section>
  );
};

export default JumbotronSlider;
