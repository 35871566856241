import React from 'react';
import { RichText } from 'prismic-reactjs';

const About = ({ text }) => {
  return (
    <section className="about">
      <div className="about-content">
        <h2 className="uppercase">About Tour</h2>

        <div
          style={{
            marginTop: '32px',
          }}
        >
          <RichText render={text} />
        </div>
      </div>
    </section>
  );
};

export default About;
