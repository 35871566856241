import React from 'react';
import { format } from 'date-fns';

import Grid from '../../../Grid';

const Jumbotron = ({ title, subtitle, backgroundImage, tourSlug, tourStartDate, tourEndDate }) => {
  const formatTourDate = (date) => format(new Date(date), 'MMMM do, yyyy');

  return (
    <div
      className="jumbotron"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
      <Grid
        className="wrapper wrapper-xl pd-x-md"
        style={{
          position: 'relative',
          zIndex: 6,
          height: '100%',
        }}>
        <Grid.Col xs="1 / span 2" md="1 / span 5" className="jumbotron-content">
          <div style={{ width: '100%' }}>
            <h2>{title}</h2>
            <h3>{subtitle}</h3>

            {/* <h4>
              {formatTourDate(tourStartDate)} - {formatTourDate(tourEndDate)}
            </h4> */}

            <div className="btn-group">
              <a href={`/contact`} className="btn btn-md btn-primary-ii btn-tour-jumbotron">
                Book Now
              </a>
              <a href={`/tours/${tourSlug}`} className="btn btn-md btn-outline btn-tour-jumbotron">
                Explore More
              </a>
            </div>
          </div>
        </Grid.Col>
      </Grid>
      <div className="bg-overlay" />
    </div>
  );
};

export default Jumbotron;
